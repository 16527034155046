


































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
// import { defineComponent } from "vue";

import {
  BCard,
  BFormFile,
  BFormCheckbox,
  BRow,
  BCol,
  BFormGroup,
} from "bootstrap-vue";
import {
  AppEditor,
  AppInput,
  AppFilePreview,
  AppForm,
  AppSelect,
  AppButton,
} from "@/components/form/index";
// import Seo from "./Seo.vue";
import GalleryModal from "@/components/UI/GalleryModal.vue";
import { toFormData } from "@/utils/index";
import SeoWrapper from "@/components/Seo/SeoWrapper.vue";

@Component({
  name: "AddNewPage",
  components: {
    AppInput,
    GalleryModal,
    AppEditor,
    BCard,
    BFormGroup,
    BFormFile,
    AppFilePreview,
    AppForm,
    BFormCheckbox,
    AppSelect,
    BRow,
    BCol,
    AppButton,
    SeoWrapper,
  },
  data() {
    return {
      successMessage: null,
      id: "add-new-page",
      form: {
        title_ar: null,
        slug_ar: null,
        content_ar: null,
        image: null,
        in_menu: "0",
        is_active: "1",
        in_footer: "0",
        category: null,
        tags: [],
        seo: {},
        // metaTitle: null,
        // metaDescription: null,
        // metaKeywords: null,
        // metaFocusKeyphrase: null,
        // facebookTitle: null,
        // facebookDescription: null,
        // facebookImage: null,
        // twitterTitle: null,
        // twitterDescription: null,
        // twitterImage: null,
      },
      seo_meta: {
        //
      },
    };
  },
  computed: {
    isLoading() {
      return (
        (this as any).$store.state.pages.isLoading.creating ||
        (this as any).$store.state.pages.isLoading.updating
      );
    },
    errorsBag() {
      return (this as any).$store.state.pages.errors;
    },
    singleData() {
      return (this as any).$store.state.pages.singleData;
    },
    doesErrorsExist() {
      return !!(
        // ((this as any).errorsBag &&
        //   Object.keys((this as any).errorsBag) &&
        //   Object.keys((this as any).errorsBag).length) ||
        (
          (this as any).errors &&
          (this as any).errors.items &&
          (this as any).errors.items.length
        )
      );
    },
  },
  created() {
    this.$store.dispatch("pages/resetModule");
    this.$route.query &&
      this.$route.query.uuid &&
      this.$store.dispatch("pages/fetchSingleItem", this.$route.query.uuid);
  },
  watch: {
    singleData: {
      handler(newVal, oldVal) {
        if (newVal) {
          this.form = newVal;
          this.seo_meta = newVal?.seo || {};
        }
      },
      deep: true,
    },
  },
  methods: {
    changeSeoHandler(key, value) {
      if (!key) return;
      (this as any).form["seo"][key] = value;
    },
    selectImageHandler(image: any) {
      (this as any).form.image = image;
    },
    cancelHandler() {
      (this as any).$emit("cancel");
      (this as any).$router.push("/pages");
    },
    openGallaryModal() {
      (this as any).$bvModal.show("gallary-modal");
    },
    submitAndAddNewHandler() {
      (this as any).$emit("submit-and-add-new");
    },
    removeImageHandler() {
      (this as any).form.image = null;
    },
    handleSubmit() {
      const actionName = (this as any).singleData ? "updateData" : "createData";

      (this as any).$store
        .dispatch(`pages/${actionName}`, toFormData((this as any).form))
        .then(() => {
          (this as any).hideForm();
          (this as any).successMessage = (this as any).$t("successfully_saved");
          (this as any).$router.push({ name: "pages" });
        });
    },
    hideForm() {
      (this as any).$bvModal.hide((this as any).id);
      (this as any).$store.dispatch("pages/resetModule");
      (this as any).form = {
        title_ar: null,
        slug_ar: null,
        content_ar: null,
        image: null,
        in_header: false,
        in_footer: false,
        category: null,
        tags: [],
        metaTitle: null,
        metaDescription: null,
        metaKeywords: null,
        metaFocusKeyphrase: null,
        facebookTitle: null,
        facebookDescription: null,
        facebookImage: null,
        twitterTitle: null,
        twitterDescription: null,
        twitterImage: null,
      };
      (this as any).$emit("hide");
    },
  },
})
export default class AddNewpage extends Vue {
  // @Prop({ type: [String, Number], required: true }) id!: string | number;
  @Prop({ type: Object, required: false }) initialData: object;
}
